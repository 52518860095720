import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ServiceSelector from "../components/ServiceSelector"

// import dezinfector from "../images/dezinfector.jpg"

const guarant = [
  {
    title: "Наши специалисты используют",
    img: "https://cdn1.iconfinder.com/data/icons/business-mix-3/100/business-26-512.png",
    desc: "Каждый наш специалист имеет опыт от 2 лет и использует самые современные методы дезинфекции, дезинсекции и дератизации. Благодаря этому вероятность возвращения к вам нежданных «гостей» сведена к минимуму.",
  },
  {
    title: "Сертифицированные препараты",
    img: "https://cdn3.iconfinder.com/data/icons/school-169/64/06-Certificate-1024.png",
    desc: "Применяем только профессиональное оборудование и высокоэффективные препараты, которые прошли обязательную сертификацию. Они точно не нанесут вред вашей семье или вашим домашним питомцам.",
  },
  {
    title: "Разумные цены",
    img: "https://cdn3.iconfinder.com/data/icons/cyber-monday-45/68/48-512.png",
    desc: "Стоимость наших услуг доступна всем в не зависимости от уровня дохода. При онлайн заказе можно получить скидку 10%. Для крупных заказчиков особые условия сотрудничества.",
  },
  {
    title: "Удобный график",
    img: "https://cdn0.iconfinder.com/data/icons/business-finance-round/128/4-512.png",
    desc: "Вы можете назначить визит специалиста на удобный для себя час, например, на вечернее время, когда вернетесь домой с работы. Также мы готовы взять срочный заказ 24/7 — подробнее об этом уточняйте у менеджера.",
  },
  {
    title: "Квалифицированные специалисты",
    img: "https://cdn1.iconfinder.com/data/icons/education-791/512/teacher-woman-education-staff-occupation-512.png",
    desc: "Наши специалисты - наши работники имеют личные сертификаты и опыт работы не менее двух лет",
  },
  {
    title: "Анонимность",
    img: "https://cdn4.iconfinder.com/data/icons/cryptocurrency-and-blockchain-banking/256/anonymity_1-512.png",
    desc: "При необходимости наши специалисты приедут без спецодежды с фирменным логотипом, чтобы не привлечь внимания любопытных соседей. А значит, соседи не узнают о вашей проблеме, если вы сами не захотите им рассказать.",
  },
]

const uslug = [
  {
    title: "Обработка домов и квартир",
    link: "obrabotka-domov-i-kvartir",
    img: "https://kfpartners.ru/images/dezinsekciya-1.jpg",
  },
  {
    title: "Обработка объектов общепита (кафе, ресторанов)",
    link: "obrabotka-restoranov-kafe",
    img: "https://kfpartners.ru/images/news/6d2755356ef.jpg",
  },

  {
    title: "Обработка офисных и производственных помещений",
    link: "dezinfection-office",
    img: "https://kfpartners.ru/images/755974243079236.jpg",
  },
  {
    title: "Уничтожение вредных грызунов",
    link: "obrabotka-ot-gryzunov",
    img: "https://kfpartners.ru/images/deratiz.jpg",
  },
  {
    title: "Очистка и дезинфекция систем вентиляции",
    link: "chistka-ventilyacii",
    img: "https://kfpartners.ru/images/news/unnamed.jpg",
  },
]

const callService = [
  {
    slug: 1,
    title: "Дезинфекция жилого помещения",
    cat: [
      { type: "1-комн. кв", price: 800 },
      { type: "2-комн. кв", price: 1000 },
      { type: "3-комн. кв", price: 1300 },
    ],
  },
  {
    slug: 2,
    title: "Дезинфекция офиса",
    cat: [
      { type: "Российский препарат", price: 12, sq: "кв.м" },
      { type: "Импортный препарат", price: 18, sq: "кв.м" },
    ],
  },
  {
    slug: 3,
    title: "Уничтожение вредных насекомых",
    cat: [
      { type: "1-комн. кв", price: 1000 },
      { type: "2-комн. кв", price: 1300 },
      { type: "3-комн. кв", price: 1600 },
    ],
  },
  {
    slug: 4,
    title: "Уничтожение вредных грызунов",
    cat: [{ type: "в частном доме", price: 0.5, sq: "кв.м" }],
  },
  {
    slug: 6,
    title: "Дезинфекция от COVID-19",
    cat: [
      { type: "Офисов, помещений", price: 12, sq: "кв.м" },
      { type: "Квартиры", price: 800 },
    ],
  },
  {
    slug: 7,
    title: "Гербицидная обработка",
    cat: [
      { type: "Офисов, помещений", price: 12, sq: "кв.м" },
      { type: "Квартиры", price: 800 },
    ],
  },
  {
    slug: 8,
    title: "Чистка вентиляции",
    cat: [
      { type: "Офисов, помещений", price: 12, sq: "кв.м" },
      { type: "Квартиры", price: 800 },
    ],
  },
  {
    slug: 9,
    title: "Дезинфекция офисных и производственных помещений",
    cat: [
      { type: "Офисов, помещений", price: 12, sq: "кв.м" },
      { type: "Квартиры", price: 800 },
    ],
  },
]

const IndexPage = () => {
  const [call, setCall] = useState({ slug: "", phone: "", name: "" })
  const [calc, setCalc] = useState({
    slug: 1,
    type: "",
    price: 0,
    sq: "",
  })

  const handleCalc = e => {
    setCalc({ slug: +e.target.value })
  }

  const setPhone = e => {
    if (!isNaN(+e.target.value)) {
      setCall({ ...call, phone: +e.target.value })
    }
  }

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...call,
      }),
    })
      .then(() => {
        navigate("/thanks")
      })
      .catch(error => alert(error))
    e.preventDefault()
  }
  return (
    <Layout>
      <Seo title="Красильников, Фролов и партнеры" />
      <div className="grid md:grid-cols-2 grid-cols-1 gap-10 py-8">
        <div>
          <StaticImage
            src="https://kfpartners.ru/images/dezinfector.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Красильников, Фролов и партнеры"
            style={{ marginBottom: `1.45rem` }}
          />
        </div>
        <div className="flex flex-col self-center space-y-10 px-4 md:px-0">
          <h1 className="md:text-5xl text-3xl font-semibold">
            Уничтожение насекомых и грызунов
          </h1>
          <div className="md:text-xl space-y-5">
            <div>
              Мгновенная ликвидация паразитов. Длительный эффект после
              обработки.&nbsp;
              <span className="underline font-semibold decoration-2 underline-offset-4 decoration-pink-500">
                Безопасно для детей и животных.&nbsp;
              </span>
            </div>
            <div>
              Используем проверенные и{" "}
              <span className="underline font-semibold decoration-2 underline-offset-4 decoration-sky-500">
                только сертифицированные
              </span>{" "}
              препараты импортного и отечественного производства
            </div>
          </div>
          <div className="p-5 space-y-4 shadow-lg rounded-lg">
            <div className="text-lg font-semibold">
              Оставьте заявку на дезинфекцию, дезинсекцию или дератизацию
            </div>
            <div>
              <form
                name="contact"
                method="POST"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                <div className="flex flex-col space-y-4 pb-4">
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="md:flex md:space-x-4 space-y-4 md:space-y-0">
                    <input
                      id="name"
                      type="text"
                      value={call.name}
                      maxLength="26"
                      name="name"
                      onChange={e => setCall({ ...call, name: e.target.value })}
                      className="p-3 bg-stone-100 rounded w-full"
                      placeholder="Введите ваше имя"
                    />
                    <input
                      id="phone"
                      type="text"
                      value={call.phone}
                      maxLength="11"
                      name="phone"
                      onChange={e => setPhone(e)}
                      className="p-3 bg-stone-100 rounded w-full"
                      placeholder="Введите Ваш телефон"
                    />
                  </div>
                  <div>
                    <select
                      value={call.slug.length < 1 ? "Выберите..." : call.slug}
                      className="p-3"
                      name="slug"
                      id="slug"
                      onChange={e => setCall({ ...call, slug: e.target.value })}
                    >
                      <option disabled>
                        {call.slug.length < 1 ? "Выберите..." : call.slug}
                      </option>
                      {callService.map(it => {
                        return (
                          <option key={it.slug} value={it.title}>
                            {it.title}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <button
                  type="submit"
                  className="flex title text-white bg-gradient-to-r from-orange-400 via-red-500 to-pink-500 p-3 hover:shadow-2xl hover:via-pink-500 hover:to-red-500 rounded-full font-semibold items-center px-10 shadow-inner text-xl"
                >
                  Оставить заявку
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="flex md:flex-row flex-col md:space-x-6 md:py-8">
        <div className="md:w-1/3">
          <div className="bg-sky-500 p-6 border-4 border-sky-600 space-y-5">
            <div className="text-xl font-semibold text-center">
              Примерный расчет стоимости
            </div>
            <div className="text-sm italic text-gray-100">
              Узнайте стоимость обработки квартиры или дома. Коммерческих
              объектов помещений, офисов
            </div>
            <div className="md:flex space-y-4 md:space-y-0 items-center md:space-x-3 text-white">
              <div className="font-semibold hidden md:block">Обработка</div>
              <div className="w-full">
                <select className="p-2 bg-sky-600 w-full" onChange={handleCalc}>
                  {callService.map(it => {
                    return (
                      <option key={it.slug} value={it.slug}>
                        {it.title}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="flex justify-evenly space-x-5 text-sm">
              {calc.slug ? (
                <>
                  {callService
                    .filter(it => it.slug === calc.slug)
                    .map(({ cat }) => {
                      return cat.map(({ type, price, sq }) => {
                        return (
                          <button
                            key={type}
                            className={`${
                              type === calc.type
                                ? "border-2 shadow-sm bg-white border-sky-800"
                                : "bg-gray-100"
                            } p-2 `}
                            onClick={() =>
                              setCalc({ ...calc, type, price, sq })
                            }
                          >
                            {type}
                          </button>
                        )
                      })
                    })}
                </>
              ) : null}
            </div>
            <div className="flex items-center text-lg font-semibold text-stone-900 space-x-5">
              <div className="text-xl text-white font-semibold">
                {calc.type && calc.slug ? (
                  <>
                    <span className="text-gray-200">Стоимость:</span> от{" "}
                    {calc.price} руб. {calc?.sq ? <>за {calc.sq}</> : null}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-2/3 font-raleway md:text-lg space-y-2 px-4 md:px-0">
          <div className="py-5 md:py-0">
            <span className="font-semibold">
              Красильников, Фролов и партнеры
            </span>{" "}
            — ветеран на рынке санэпидем обработки. За более 5 лет работы мы
            выстроили эффективную систему
            <ul className="py-2 list-inside list-disc">
              <li>предоставления квалифицированных услуг</li>
              <li>
                любимым клиентам отбора добросовестных сотрудников и постоянного
                их обучения
              </li>
              <li>тестирования оборудования и закупки лучших образцов</li>
              <li>
                проведения анализа и отбора оптимальных препаратов для обработки
              </li>
            </ul>
            <span className="underline underline-offset-4 decoration-2 decoration-violet-500">
              Мы всегда на стороне клиента
            </span>{" "}
            — выберем время для обработки с учетом ваших пожеланий, оптимальные
            по цене и качеству препараты, сделаем скидку!
          </div>
          <div>
            <span className="font-semibold text-stone-700">
              Обратите внимание!
            </span>{" "}
            <span className="underline underline-offset-4 decoration-2 decoration-pink-500">
              Хорошие препараты не могут стоить дешево!
            </span>{" "}
            Мы всегда обрабатываем качественными составами, которые не вызовут у
            вас нареканий.{" "}
          </div>
          <div className="p-4 bg-red-50">
            <p>
              <span className="font-semibold text-stone-700">Осторожно!</span> В
              настоящее время на рынке услуг дезобработки появилось много
              сайтов-однодневок, которые обещают сделать работу в два раза
              дешевле, чем у отвественных и лицензированных организаций. Такие
              фирмы используют:
            </p>
            <ul className="list-inside list-disc pl-6 pt-2 italic">
              <li>Разбавленные несертифицированные препараты ради экономии;</li>
              <li>
                Препараты с повышенным классом опасности дезинфицирующих средств
                и высокой токсичности;
              </li>
              <li>
                Небезопасные препараты, как для вас так и для домашних животных
              </li>
            </ul>
          </div>
          <div className="text-stone-600 italic">
            Вы обнаружите мошенничество через несколько часов после их «работы».
            Будьте осторожны!
          </div>
        </div>
      </div>
      <div className="text-xl text-center text-sky-700 font-semibold font-raleway hidden md:block">
        Наша компания дорожит репутацией и временем своих клиентов. Всегда рады
        помочь!
      </div>
      <div className="md:py-10 py-4">
        <div className="text-4xl font-jost font-semibold text-center">
          Наши{" "}
          <span className="underline underline-offset-6 decoration-4 decoration-pink-500">
            услуги
          </span>
        </div>
        <div className="grid md:grid-cols-3 grid-cols-2 md:gap-8 gap-2 py-8">
          {uslug.map(it => {
            return (
              <div key={it.title}>
                <Link to={`/uslugi/${it?.link}`}>
                  <img className="w-full h-64" src={it.img} alt={it.title} />
                  <div className="bg-sky-600 md:p-4 p-1 h-16 text-white text-center font-raleway font-semibold text-sm md:text-sm">
                    {it.title}
                  </div>
                </Link>
              </div>
            )
          })}
        </div>
      </div>
      <div className="md:py-16">
        <ServiceSelector />
      </div>
      <div>
        <div className="text-4xl font-jost font-semibold text-center">
          Почему{" "}
          <span className="underline underline-offset-8 decoration-8 decoration-pink-500">
            мы
          </span>
        </div>
        <div className="grid md:grid-rows-2 md:grid-flow-col gap-4 py-10 font-raleway">
          {guarant.map(it => {
            return (
              <div className="flex flex-col bg-sky-100 p-4" key={it.title}>
                <img className="w-20 self-center" src={it.img} alt={it.title} />
                <span className="font-semibold text-center py-3">
                  {it.title}
                </span>
                <div className="text-sm italic text-stone-600">{it.desc}</div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
