import { Link } from "gatsby"
import * as React from "react"

import bug from "../images/boxelder-bug.png"
import covid from "../images/covid-19.png"
import rat from "../images/rat.png"
import venti from "../images/ventilation.png"

const dez = [
  {
    title: "Тараканы",
    img: "https://dez-effect.ru/wp-content/uploads/2021/01/db8553c82442afda042c5e4abbabac2e.jpg",
    section: "dezinsec",
    url: "unichtozhenie-tarakanov",
  },
  {
    title: "Клещи",
    img: "https://dez-effect.ru/wp-content/uploads/2021/07/services-type-1-5.jpg",
    section: "dezinsec",
    url: "unichtozhenie-kleshhey",
  },
  {
    title: "Клопы",
    img: "https://dez-effect.ru/wp-content/uploads/2021/07/services-type-1-3.jpg",
    section: "dezinsec",
    url: "unichtozhenie-klopov",
  },
  {
    title: "Моль",
    img: "https://dez-effect.ru/wp-content/uploads/2019/12/services-type-1-9.jpg",
    section: "dezinsec",
    url: "unichtozhenie-moli",
  },
  {
    title: "Древоточец",
    img: "https://dez-effect.ru/wp-content/uploads/2021/01/23403.phd5dc.600.jpg",
    section: "dezinsec",
    url: "fumigaciya-drevotochca",
  },
  {
    title: "Короед",
    img: "https://dez-effect.ru/wp-content/uploads/2019/12/services-type-1-2.jpg",
    section: "dezinsec",
    url: "unichtozhenie-zhuka-koroeda",
  },
  {
    title: "Мухи",
    img: "https://dez-effect.ru/wp-content/uploads/2021/01/1522307246_komnatnaya-muha.jpg",
    section: "dezinsec",
    url: "unichtozhenie-muh",
  },
  {
    title: "Осы и шершни",
    img: "https://dez-effect.ru/wp-content/uploads/2021/01/rossiya-osa-39319_1.jpg",
    section: "dezinsec",
    url: "unichtozhenie-os",
  },
  {
    title: "Комары",
    img: "https://dez-effect.ru/wp-content/uploads/2021/01/skrinshot-11-01-2021-142729.jpg",
    section: "dezinsec",
    url: "unichtozhenie-komarov",
  },
  {
    title: "Муравьи",
    img: "https://dez-effect.ru/wp-content/uploads/2019/12/services-type-1-10.jpg",
    section: "dezinsec",
    url: "unichtozhenie-muraviev",
  },
  {
    title: "Блохи",
    img: "https://dez-effect.ru/wp-content/uploads/2019/12/services-type-1-14.jpg",
    section: "dezinsec",
    url: "unichtozhenie-bloh",
  },
  {
    title: "Плесень",
    img: "https://dez-effect.ru/wp-content/uploads/2019/12/services-type-2-2.jpg",
    section: "dezinfec",
    url: "obrabotka-ot-pleseni-i-gribka",
  },
  {
    title: "Вирусы",
    img: "https://dez-effect.ru/wp-content/uploads/2019/12/services-type-2-1.jpg",
    section: "dezinfec",
    url: "unichtozhenie-mikroorganizmov",
  },
  {
    title: "Автотранспорт",
    img: "https://dez-effect.ru/wp-content/uploads/2021/01/129257_8f21ba3da6f632496b51a2654aa3511f22af0578.jpg",
    section: "dezinfec",
    url: "dezinfekciya-auto",
  },
  {
    title: "Квартир",
    img: "https://dez-effect.ru/wp-content/uploads/2019/12/services-type-2-7.jpg",
    section: "dezinfec",
    url: "obrabotka-domov-i-kvartir",
  },
  {
    title: "Мыши",
    img: "https://dez-effect.ru/wp-content/uploads/2021/01/12-2-768x432-1.jpg",
    section: "der",
    url: "unichtozhenie-myshey",
  },
  {
    title: "Крысы",
    img: "https://dez-effect.ru/wp-content/uploads/2021/01/doc6fvenemkwh1160ld33tq_800_480.jpg",
    section: "der",
    url: "unichtozhenie-krys",
  },
  {
    title: "Кроты",
    img: "https://dez-effect.ru/wp-content/uploads/2019/12/services-type-3-3.jpg",
    section: "der",
    url: "unichtozhenie-krotov",
  },
  {
    title: "Чистка вентиляционной системы",
    img: "https://mir-klimata.info/images/2020/20200512/vclean1.png",
    section: "vent",
    url: "chistka-ventilyacii",
  },
]

const ServiceSelector = () => {
  const [selectBtn, setSelectBtn] = React.useState({ checked: "dezinsec" })
  const handleSelect = e => {
    return setSelectBtn({ checked: e.currentTarget.value })
  }
  const selButton = selectBtn.checked
    ? "underline decoration-4 underline-offset-8 decoration-pink-500 font-semibold"
    : "font-semibold"

  return (
    <>
      <div className="lg:flex lg:space-x-10 grid grid-cols-2 md:grid-cols-4 gap-y-8 font-raleway font-semibold text-stone-700 mb-1 border-b-2">
        <button type="button" value="dezinsec" onClick={handleSelect}>
          <div className="flex flex-col text-center">
            <img className="w-20 self-center" src={bug} alt="Дезинсекция" />
            <div className={selectBtn.checked === "dezinsec" ? selButton : ""}>
              Дезинсекция
            </div>
          </div>
        </button>
        <button type="button" value="dezinfec" onClick={handleSelect}>
          <div className="flex flex-col text-center">
            <img className="w-20 self-center" src={covid} alt="Дезинфекция" />
            <div className={selectBtn.checked === "dezinfec" ? selButton : ""}>
              Дезинфекция
            </div>
          </div>
        </button>
        <button type="button" value="der" onClick={handleSelect}>
          <div className="flex flex-col text-center">
            <img className="w-20 self-center" src={rat} alt="Дератизация" />
            <div className={selectBtn.checked === "der" ? selButton : ""}>
              Дератизация
            </div>
          </div>
        </button>
        <button type="button" value="vent" onClick={handleSelect}>
          <div className="flex flex-col text-center">
            <img
              className="w-20 self-center"
              src={venti}
              alt="Система вентиляции"
            />
            <div className={selectBtn.checked === "vent" ? selButton : ""}>
              Система вентиляции
            </div>
          </div>
        </button>
      </div>
      <div className="grid lg:grid-cols-6 grid-cols-3 md:grid-cols-4 lg:gap-8 gap-2 lg:py-10 py-4 font-raleway">
        {dez
          .filter(dez => dez.section === selectBtn.checked)
          .map(it => {
            return (
              <div
                className="text-center flex flex-col"
                key={it.title}
              >
                {it?.url ? (
                  <Link to={`/uslugi/${it.url}`} className="mx-auto">
                    <img
                      className="rounded-full self-center bg-center lg:w-40 lg:h-40 w-20 h-20 border-8 border-white shadow-md"
                      src={it.img}
                      alt={it.title}
                    />
                  </Link>
                ) : (
                  <img
                    className="rounded-full self-center bg-center lg:w-40 lg:h-40 w-20 h-20 border-8 border-white shadow-md"
                    src={it.img}
                    alt={it.title}
                  />
                )}
                <h3 className="py-3 font-semibold md:text-lg">
                  {it?.url ? (
                    <Link to={`/uslugi/${it.url}`}>{it.title}</Link>
                  ) : (
                    <>{it.title}</>
                  )}
                </h3>
              </div>
            )
          })}
      </div>
    </>
  )
}

export default ServiceSelector
